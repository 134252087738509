import { type ReactNode } from 'react';
import { cn } from '@/lib/utils';

interface Props {
  children: ReactNode;
  className?: string;
}

export function LayoutContainer({ children, className }: Props) {
  return (
    <div
      className={cn(
        'w-full l:w-92% flex l:max-w-900 m-0 px-24 pb-40 pt-90 l:pt-40 s:px-64 m:px-118 l:px-0 l:mx-auto',
        className,
      )}
    >
      {children}
    </div>
  );
}
