'use client';

import { useState } from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { navigationConfig } from '@/configs/index-page-config';
import { useActiveLink } from '@/lib/use-active-link';
import { Button } from '@/components/atoms/button';
import { ScrollLink } from '@/components/atoms/scroll-link';
import { HamburgerIcon } from '@/components/atoms/hamburger-icon';

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathname = usePathname();
  const { activeLink } = useActiveLink(navigationConfig);

  const toggleBodyClass = (isMenuOpenState: boolean) => {
    if (window.innerWidth < 1024) {
      if (isMenuOpenState) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    toggleBodyClass(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    toggleBodyClass(!isMenuOpen);
  };

  return (
    <header className="fixed top-0 left-0 z-20 w-full lg:w-40% flex items-center bg-foreground text-l l:text-base leading-32">
      <Link
        href="/"
        className="flex flex-col l:flex-row w-fit m-0 px-24 py-10 l:px-0 l:p-0 l:mx-0 text-sm l:text-base text-primary"
      >
        <span className="block l:px-10 xl:px-30 l:py-19 l:bg-primary l:text-foreground l:font-bold uppercase l:text-base 3xl:text-l">
          Psycholog
        </span>

        <h2 className="flex flex-col l:flex-row leading-26 l:leading-32 pl-0 l:px-10 l:py-19 xl:px-30 3xl:text-l">
          Dominika Krata
        </h2>
      </Link>

      <Button
        onClick={toggleMenu}
        aria-label="Menu toggle"
        className="flex justify-center items-center w-35 h-35 p-0 bg-0 ml-auto mr-20"
      >
        <HamburgerIcon isMenuOpen={isMenuOpen} />
      </Button>

      <div
        className={`fixed top-70 bottom-0 ${
          isMenuOpen ? 'left-0' : 'left-[-100vw]'
        } w-full lg:w-40% flex flex-col items-center justify-center bg-foreground text-white overflow-y-auto transition-all duration-500`}
      >
        <nav>
          <ul className="text-center uppercase font-bold">
            {pathname !== '/' && (
              <li onClick={closeMenu}>
                <Link href="/" className="block p-5 hover:text-primary">
                  Powrót do strony głównej
                </Link>
              </li>
            )}

            {pathname === '/' &&
              navigationConfig.map((item, index) => (
                <li key={index} onClick={closeMenu}>
                  {item.link[0] === '#' ? (
                    <ScrollLink
                      href={item.link}
                      className={`block p-5 hover:text-primary ${
                        activeLink === item.link ? 'text-primary' : ''
                      }`}
                    >
                      {item.name}
                    </ScrollLink>
                  ) : (
                    <Link
                      href={item.link}
                      className={`block p-5 hover:text-primary ${
                        activeLink === item.link ? 'text-primary' : ''
                      }`}
                    >
                      {item.name}
                    </Link>
                  )}
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </header>
  );
}
