export const indexPageConfig = [
  {
    sectionType: 'about-me',
    sectionId: 'about-me',
    headingText: 'PSYCHOLOG, TERAPEUTA, TRENER, MEDIATOR',
    headingCaptionText: 'o mnie',
    image: {
      src: 'images/profile.jpg',
      alt: 'Zdjęcie Dominika Krata',
    },
    contentSubTitle: 'dominika krata',
    contentTitle: `<strong>specjalista</strong> pracy terapeutycznej z dziećmi, młodzieżą i dorosłymi`,
    contentText: `Jestem psychologiem (uczelnia: SWPS Uniwersytet Humanistycznospołeczny, rok ukończenia: 2016), trenerem umiejętności miękkich i mediatorem. Mam także kwalifikacje pedagogiczne. Specjalizuję się głównie w pracy terapeutycznej i rozwojowej z dziećmi i młodzieżą (10-18 lat) oraz młodymi dorosłymi (18-30 lat). Prowadzę także konsultacje wychowawcze dla rodziców, warsztaty, szkolenia, grupy terapeutyczne i rozwojowe. Zapraszam do mojego gabinetu w Warszawie, a także na sesje online.`,
    contentButtonText: 'zobacz czym się zajmuję',
    listItems: [
      {
        id: 1,
        title: 'W swojej pracy korzystam z:',
        listDecoration: 'border',
        listImage: {
          src: 'images/icon-hand-point-right.svg',
          alt: 'Ikona ręki wskazującej w prawo',
        },
        items: [
          {
            id: 1,
            text: 'metody komunikacji NVC Marshalla B. Rosenberga (Nonviolent Communication - Porozumienie bez przemocy)',
          },
          {
            id: 2,
            text: 'metod pracy z emocjami (umiejętność rozpoznawania emocji i samoregulacji w oparciu m.in. o technikę uwalniania dr Davida R. Hawkinsa)',
          },
          {
            id: 3,
            text: 'technik uważności/mindfulness oraz wizualizacji',
          },
          {
            id: 4,
            text: 'metod pracy z ciałem (techniki relaksacyjne, podstawy teorii poliwagalnej, ćwiczenia inspirowane terapią tańcem i ruchem)',
          },
          {
            id: 5,
            text: 'metod kreatywnych (rysunek, praca z kartami/zdjęciami)',
          },
          {
            id: 6,
            text: 'współpracy z innymi specjalistami (np. psychiatrą, mediatorem), a także z instytucjami do których uczęszcza dziecko (np. szkołą)',
          },
        ],
      },
    ],
  },
  {
    sectionType: 'trainings-and-courses',
    sectionId: 'trainings-and-courses',
    headingText: 'ukończone szkolenia i doświadczenie',
    headingCaptionText: 'KWALIFIKACJE',
    listItems: [
      {
        listImage: {
          src: 'images/icon-graduation-cap.svg',
          alt: 'Ikona biret',
        },
        list: [
          {
            id: 1,
            text: 'szkolenie wprowadzające oraz pierwszy stopień <strong>Dialogu Motywującego</strong>',
          },
          {
            id: 2,
            text: 'pierwszy stopień <strong>Terapii Skoncentrowanej na Rozwiązaniu</strong> (TSR)',
          },
          {
            id: 3,
            text: 'kurs kwalifikacyjny TZK (<strong>Metoda ART</strong> - Trening Zastępowania Agresji)',
          },
          {
            id: 4,
            text: 'szkolenie z kształtowania u dzieci i młodzieży <strong>kompetencji uniwersalnych</strong> oraz warsztaty rozwijające <strong>kompetencje z zakresu edukacji włączającej</strong>, w tym prowadzenia zajęć włączających dla uczniów ze specjalnymi potrzebami edukacyjnymi',
          },
          {
            id: 5,
            text: 'praktyczne szkolenie kwalifikacyjne w zakresie procedury i zasad prowadzenia mediacji w podejściu <strong>NVC</strong> (80 h)',
          },
          {
            id: 6,
            text: 'udział w rocznej <strong>Szkole Trenerów Warsztatów Umiejętności Psychospołecznych</strong> (220 h)',
          },
          {
            id: 7,
            text: 'szkolenie “Jak prowadzić TUS - <strong>Trening Umiejętności Społecznych</strong> dla dzieci i młodzieży”',
          },
          {
            id: 8,
            text: 'kurs <strong>Breathworks Mindfulness</strong> dla Zdrowia (praktyka uważności oraz życzliwości wobec siebie oraz innych)',
          },
          {
            id: 9,
            text: 'podstawowy i zaawansowany kurs analizy ruchu - “<strong>Laban Bartenieff Movement System</strong> (LBMS)“, zawierający elementy psychoterapii tańcem i ruchem',
          },
        ],
      },
      {
        listImage: {
          src: 'images/icon-briefcase.jpg',
          alt: 'Ikona teczki',
        },
        list: [
          {
            id: 1,
            text: '2022 - obecnie prowadzenie własnego gabinetu w Warszawie oraz współpraca ze szkołami i innymi instytucjami / organizacjami',
          },
          {
            id: 2,
            text: '2024 - obecnie współtworzenie podcastów “Terapeutyczne rozmowy o sztuce” na kanale SENSytywnie z Alicją (Youtube, Spotify)'
          },
          {
            id: 3,
            text: '2022 - obecnie terapeuta w Poradni Dom Rozwoju',
          },
          {
            id: 4,
            text: '2022 - 2024 terapeuta i trener TUS w Centrum Terapii i Rozwoju Indygo',
          },
          {
            id: 5,
            text: '2019 - 2022 psycholog w szkole Akademii Dobrej Edukacji',
          },
          {
            id: 6,
            text: '2018 - 2021 terapeuta w poradni Kalm - Centrum Psychoterapii i Rozwoju',
          },
          {
            id: 7,
            text: '2017 - 2019 terapeuta środowiskowy w Stowarzyszeniu Terapeutów; terapeuta i trener TUS w Fundacji Scolar',
          },
          {
            id: 8,
            text: '2016 - 2017 wsparcie terapeuty w Przedszkolu Terapeutycznym Fundacji Synapsis',
          },
          {
            id: 9,
            text: '2017 - 2020 koordynator projektu SENSytywni.pl (cel projektu: aktywizowanie osób wrażliwych - dorosłych i młodzieży, edukacja w zakresie budowania dialogu); współprowadzenie spotkań (rozmów) tematycznych oraz warsztatów rozwojowych (praca z ciałem i ruchem, komunikacja empatyczna w podejściu NVC)',
          },
        ],
      },
    ],
  },
  {
    sectionType: 'work-area',
    sectionId: 'work-area',
    headingText: 'w czym mogę ci pomóc',
    headingCaptionText: 'obszary pracy',
    image: {
      src: 'images/icon-hand-point-right.svg',
      alt: 'Ikona ręki wskazującej w prawo',
    },
    listItems: [
      {
        id: 1,
        title:
          'Zapraszam, jeśli czujesz, że Ty lub Twoje dziecko potrzebujecie rozwoju w obszarach takich jak:',
        listDecoration: 'border',
        items: [
          {
            id: 1,
            text: 'rozwijanie świadomości siebie, swoich emocji i potrzeb oraz umiejętności wyrażania ich w zgodzie ze sobą i otoczeniem',
          },
          {
            id: 2,
            text: 'nauka konstruktywnego radzenia sobie z trudnymi emocjami (np. złość, lęk, smutek)',
          },
          {
            id: 3,
            text: 'kształtowanie umiejętności radzenia sobie w trudnych (np. stresujących) sytuacjach, poszukiwania wsparcia i pomocy',
          },
          {
            id: 4,
            text: 'kształtowanie poczucia własnej wartości, mocnych stron i zainteresowań',
          },
          {
            id: 5,
            text: 'zdobywanie umiejętności rozwiązywania konfliktów, budowania współpracy w relacjach',
          },
          {
            id: 6,
            text: 'nabywanie kompetencji społecznych, rozwijanie wrażliwości i empatii',
          },
          {
            id: 7,
            text: 'wzmacnianie motywacji do nauki szkolnej i/lub pracy nad sobą (w tym organizacji pracy i wyznaczania priorytetów)',
          },
          {
            id: 8,
            text: 'ukierunkowanie dotyczące przyszłości - wyboru szkoły, studiów, zawodu',
          },
        ],
      },
      {
        id: 2,
        title:
          'Zapraszam również, jeśli Ty lub Twoje dziecko potrzebujecie wsparcia i pomocy w sytuacjach:',
        listDecoration: 'dot',
        items: [
          {
            id: 1,
            text: 'obniżonego nastroju, stanów lękowych',
          },
          {
            id: 2,
            text: 'agresji, napadów niekontrolowanej złości',
          },
          {
            id: 3,
            text: 'zaburzeń odżywiania',
          },
          {
            id: 4,
            text: 'autoagresji (w tym samookaleczanie)',
          },
          {
            id: 5,
            text: 'trudności rodzinnych (w tym rozwód, konflikty)',
          },
          {
            id: 6,
            text: 'niskiej samooceny',
          },
          {
            id: 7,
            text: 'trudności w nawiązywaniu i podtrzymaniu relacji społecznych',
          },
          {
            id: 8,
            text: 'zaobserwowanych trudności w rozwoju społeczno-emocjonalnym',
          },
          {
            id: 9,
            text: 'dużej niechęci do aktywności szkolnej lub innej, podejmowania decyzji, brania odpowiedzialności',
          },
          {
            id: 10,
            text: 'uzależnienia od środków psychoaktywnych, gier, mediów społecznościowych',
          },
        ],
      },
    ],
  },
  {
    sectionType: 'services',
    sectionId: 'services',
    headingText: 'sprawdź moje usługi',
    headingCaptionText: 'usługi',
    listItems: [
      {
        id: 1,
        title: 'TERAPIA DLA DZIECI I MŁODZIEŻY (10-18 LAT)',
        text: 'Na pierwsze spotkanie zapraszam rodziców na rozmowę o dziecku, niezależnie od jego wieku. Na nim omówimy występujące obecnie i w przeszłości trudności oraz obszary do rozwoju. Na kolejne sesje terapeutyczne przychodzi już samo dziecko. Później w razie potrzeby rodzice mogą skorzystać z konsultacji wychowawczych.',
      },
      {
        id: 2,
        title: 'KONSULTACJE DLA DZIECI I MŁODZIEŻY (10-18 LAT)',
        text: 'Skorzystaj, gdy nie wiesz czy dziecko powinno rozpocząć terapię, potrzebuje dłuższego wsparcia lub potrzebujesz informacji czy dziecko rozwija się prawidłowo i jak można je wspierać. Są to 4 spotkania. Pierwsze ma formę rozmowy z rodzicami. Następnie dwa to spotkania z dzieckiem. Czwarte spotkanie z rodzicami zawiera podsumowanie oraz podzielenie się wskazówkami i zaleceniami.',
      },
      {
        id: 3,
        title: 'KONSULTACJE WYCHOWAWCZE DLA RODZICÓW',
        text: 'Na spotkaniach dowiesz się jak adekwatnie do wieku dziecka możesz je prawidłowo wspierać, lepiej rozumieć i jak się z nim komunikować tak, aby pielęgnować wzajemność i współpracę w rodzinie.',
      },
      {
        id: 4,
        title: 'KONSULTACJE DLA MŁODYCH DOROSŁYCH (18-30 LAT)',
        text: 'Zapraszam na pierwsze spotkanie, na którym omówimy bieżące potrzeby i ustalimy, które cele są możliwe do zrealizowania w ramach kolejnych sesji.',
      },
      {
        id: 5,
        title: 'GRUPOWA TERAPIA ROZWOJOWA',
        text: 'Terapia grupowa jest prowadzona w grupie do 12 osób. Przed przyjęciem do grupy wymagana jest wstępna konsultacja z prowadzącym grupę. Służy ona udzieleniu potrzebnych informacji, a także potwierdzeniu czy ten rodzaj zajęć jest dobrym kierunkiem. Terapia grupowa to doświadczenie, które umożliwia rozwój, pozwala uzyskać wsparcie wspólnoty i uczy współpracy z innymi.',
      },
      {
        id: 6,
        title: 'SZKOLENIA I WARSZTATY W NURCIE NVC',
        text: 'Zapraszam do kontaktu, jeśli miejsce w którym pracujesz potrzebuje wsparcia w zakresie komunikacji i umiejętności rozwiązywania konfliktów. Oferta kierowana jest do szkół (nauczycieli, uczniów, rodziców), a także firm i innych społeczności. Szkolenia/warsztaty prowadzone są w oparciu o metodę „Porozumienie bez Przemocy” Marshalla B. Rosenberga. Zawierają część teoretyczną, czyli podstawy NVC (4 kroki - obserwacja, emocje, potrzeby, prośba) oraz część praktyczną, podczas której zdobyta wiedza jest utrwalana w praktycznych ćwiczeniach mających zastosowanie w życiu/pracy.',
      },
    ],
  },
  {
    sectionType: 'pricing',
    sectionId: 'pricing',
    headingText: 'poznaj ceny usług',
    headingCaptionText: 'cennik',
    listItems: [
      {
        id: 1,
        text: 'TERAPIA DLA DZIECI I MŁODZIEŻY',
        priceText: '190 zł (50 min.)',
      },
      {
        id: 2,
        text: 'KONSULTACJE DLA DZIECI I MŁODZIEŻY',
        priceText: '760 zł (4 x 50 min.)',
      },
      {
        id: 3,
        text: 'KONSULTACJE WYCHOWAWCZE DLA RODZICÓW',
        priceText: '190 zł (50 min.)',
      },
      {
        id: 4,
        text: 'KONSULTACJE DLA MŁODYCH DOROSŁYCH',
        priceText: '190 zł (50 min.)',
      },
    ],
  },
];

export const navigationConfig = [
  {
    name: 'O mnie',
    link: '#about-me',
  },
  {
    name: 'Kwalifikacje',
    link: '#trainings-and-courses',
  },
  {
    name: 'Obszary pracy',
    link: '#work-area',
  },
  {
    name: 'Usługi',
    link: '#services',
  },
  {
    name: 'Cennik',
    link: '#pricing',
  },
  {
    name: 'Kontakt',
    link: '/kontakt',
  },
];

export const heroConfig = {
  sectionId: 'hero',
  title: 'psycholog, trener',
  description:
    '“W życiu szukam współczucia, przepływu między sobą a innymi ludźmi, opartego na wzajemnym dawaniu z serca.”',
  subDescription: 'Marshall B. Rosenberg',
  ctaText: 'Sprawdź jak mogę ci pomóc',
  ctaLink: '#about-me',
};

export const asideConfig = [
  {
    sectionId: 'about-me',
    sectionTitle: 'O mnie',
    backgroundPattern: '/images/aside-pattern-about-me.jpg',
  },
  {
    sectionId: 'trainings-and-courses',
    sectionTitle: 'Kwalifikacje',
    backgroundPattern: '/images/aside-pattern-trainings-and-courses.jpg',
  },
  {
    sectionId: 'work-area',
    sectionTitle: 'Obszary pracy',
    backgroundPattern: '/images/aside-pattern-work-area.jpg',
  },
  {
    sectionId: 'services',
    sectionTitle: 'Usługi',
    backgroundPattern: '/images/aside-pattern-services.jpg',
  },
  {
    sectionId: 'pricing',
    sectionTitle: 'Cennik',
    backgroundPattern: '/images/aside-pattern-pricing.jpg',
  },
];
