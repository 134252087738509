'use client';

import Link from 'next/link';
import { useEffect, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';

import { Text } from '@/components/atoms/text';
import { Button } from '@/components/atoms/button';

export function CookieBaner() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const cookieAccepted = getCookie('cookie-accepted');

    if (!cookieAccepted) {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);

    setCookie('cookie-accepted', 'true', { secure: true, maxAge: 60 * 60 * 24 * 365 });
  };

  if (!isOpen) return null;

  return (
    <section className="fixed bottom-0 left-0 z-20 flex flex-col lg:flex-row justify-between items-center w-full p-30 space-x-0 lg:space-x-30 space-y-30 lg:space-y-0 bg-foreground text-white">
      <Text size="2xs">
        Strona korzysta z plików cookie w celu realizacji usług zgodnie z{' '}
        <Link href="/polityka-prywatnosci-i-cookies" className="text-primary hover:underline">
          Polityką Cookies
        </Link>
        . Możesz określić warunki przechowywania lub dostępu mechanizmu cookie w Twojej
        przeglądarce.
      </Text>

      <Button
        onClick={handleClose}
        className="text-center w-fit text-3xs leading-15 py-24 px-64 text-black font-extrabold uppercase rounded-md shadow-md bg-primary"
      >
        Nie pokazuj więcej
      </Button>
    </section>
  );
}
