interface Props {
  isMenuOpen: boolean;
}

const spanCommonStyles = 'h-3 bg-white group-hover:bg-primary transition-all duration-300';

export function HamburgerIcon({ isMenuOpen }: Props) {
  return (
    <div className="relative w-32 h-25 group">
      <span
        className={`absolute left-0 w-full ${
          isMenuOpen ? 'top-11 rotate-45' : 'top-0 rotate-0'
        } ${spanCommonStyles}`}
      ></span>
      <span
        className={`absolute top-11 left-0 w-full ${
          isMenuOpen ? 'opacity-0' : 'opacity-100'
        } ${spanCommonStyles}`}
      ></span>
      <span
        className={`absolute bottom-0 left-0 w-full ${
          isMenuOpen ? 'bottom-11 rotate-[-45deg]' : 'bottom-0 rotate-0'
        } ${spanCommonStyles}`}
      ></span>
    </div>
  );
}
