import { type ReactNode } from 'react';
import { Heading, HeadingTypeEnum } from '@/components/atoms/heading';

interface Props {
  title: string;
  children: ReactNode;
}

export function FooterColumn({ title, children }: Props) {
  return (
    <div className="2xl:w-1/3 pb-40 l:pb-20 2xl:pb-0 s:px-20">
      <Heading
        type={HeadingTypeEnum.H4}
        size="xs"
        className="relative mb-20 p-0 font-bold uppercase before:content-[''] before:absolute before:left-0 before:bottom-[-7px] before:w-60 before:h-2 before:bg-primary"
      >
        {title}
      </Heading>

      {children}
    </div>
  );
}
