'use client';

import { useCallback, useEffect, useState } from 'react';

import type { NavItemProps } from '@/components/organisms/navigation';

export function useActiveLink(navConfig: NavItemProps[]) {
  const [activeLink, setActiveLink] = useState<string | null>(null);

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;

    for (const item of navConfig) {
      const section = document.getElementById(item.link.replace('#', ''));

      if (!section) break;

      const scrolledValue = scrollY - windowHeight;
      const sectionTopValue = section.offsetTop - 300;
      const sectionBottomValue = sectionTopValue + section.clientHeight;

      if (
        sectionTopValue <= scrolledValue &&
        sectionBottomValue >= scrolledValue &&
        activeLink !== item.link
      ) {
        setActiveLink(item.link);
      }
    }
  }, [navConfig, activeLink]);

  useEffect(() => {
    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return { activeLink };
}
