'use client';

import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { siteConfig } from '@/configs/site-config';
import { Text } from '@/components/atoms/text';
import { Icons } from '@/components/atoms/icons';
import { Button } from '@/components/atoms/button';
import { LayoutContainer } from '@/components/layout/layout-container';
import { FooterColumn } from './footer-column';

const borderStyles =
  'relative p-20 before:content-[""] before:bg-primary before:top-0 before:left-0 before:absolute before:w-50 before:h-2 after:content-[""] after:bg-primary after:top-0 after:left-0 after:absolute after:w-2 after:h-50';

export function Footer() {
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef<HTMLElement>(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (window.innerWidth < 1024) return;

    if (footerRef.current?.clientHeight) {
      setFooterHeight(footerRef.current.clientHeight);
    }
  }, []);

  return (
    <>
      <div style={{ height: footerHeight }} className="hidden lg:block lg:w-full" />

      <footer
        ref={footerRef}
        className="relative lg:fixed lg:bottom-0 lg:right-0 lg:w-[60%] text-background"
      >
        <LayoutContainer className="flex-col flex-wrap l:flex-nowrap s:flex-row s:justify-between bg-foreground">
          <FooterColumn title="Znajdź mnie">
            <div className="pt-5 flex gap-4">
              <a
                href="https://www.facebook.com/profile.php?id=100094112153889"
                target="_blank"
                aria-label="Facebook"
                className="inline-block p-10 bg-primary rounded-lg"
              >
                <Icons.facebook className="w-20 h-20 text-black" />
              </a>

              <a
                href="https://www.instagram.com/psycholog_panikrata/"
                target="_blank"
                aria-label="Instagram"
                className="inline-block p-10 bg-primary rounded-lg"
              >
                <Icons.instagram className="w-20 h-20 text-black" />
              </a>
            </div>
          </FooterColumn>

          <FooterColumn title="Zapisy na wizyty">
            <div className="flex flex-col gap-2">
              <a
                href="tel:+48792988676"
                className="py-5 inline-flex items-center text-3xs font-bold uppercase hover:underline"
              >
                <Icons.phone className="w-16 h-16 mr-10 text-primary" />
                792 988 676
              </a>

              <a
                href="mailto:dominikakrata@gmail.com"
                className="py-5 inline-flex items-center text-3xs font-bold uppercase hover:underline"
              >
                <Icons.mail className="w-16 h-16 mr-10 text-primary" />
                dominikakrata@gmail.com
              </a>

              <Link
                href="/kontakt"
                onClick={scrollToTop}
                className="py-5 text-3xs font-bold uppercase text-center w-full mt-10 p-10 border-2 border-white hover:bg-primary hover:border-primary hover:text-black transition-all duration-200 ease-linear"
              >
                napisz do mnie
              </Link>

              <Link
                target="_blank"
                href="https://www.google.com/maps/place/Psycholog+Dominika+Krata/@52.193186,21.016206,16z/data=!4m6!3m5!1s0x471ecd071a92def9:0xb54ab160858ee4b5!8m2!3d52.1931862!4d21.0162059!16s%2Fg%2F11kr8t5t5p"
                className="py-5 text-3xs font-bold uppercase text-center w-full mt-10 p-10 border-2 border-white hover:bg-primary hover:border-primary hover:text-black transition-all duration-200 ease-linear"
              >
                sprawdź dojazd
              </Link>
            </div>
          </FooterColumn>

          <FooterColumn title="Przyjmuję">
            <div className="flex flex-col gap-2">
              <Text className="py-5 text-3xs font-bold uppercase">
                w gabinecie w Warszawie, ul.&nbsp;Antoniego Malczewskiego 37/41.
              </Text>

              <Text className="py-5 text-3xs font-bold uppercase">
                online poprzez skype lub google meets.
              </Text>

              <Text className="mt-20 py-5 text-3xs font-bold uppercase">
                Zdjęcia: @BE PRESENT ARTS
              </Text>

              <Link
                href="/polityka-prywatnosci-i-cookies"
                className="py-5 text-3xs font-bold uppercase hover:underline"
              >
                Polityka prywatności i cookies
              </Link>
            </div>
          </FooterColumn>
        </LayoutContainer>

        <div className="bg-black">
          <LayoutContainer className="justify-between gap-3 py-0 lg:py-0 pt-25 lg:pt-40 bg-black l:pb-0">
            <Text className={`text-3xs font-bold uppercase ${borderStyles}`}>
              &copy; {`${new Date().getFullYear()} All rights reserved dominikakrata.pl. `}
              Made with love by{' '}
              <a href="mailto:kontakt@visionative.com" className="hover:underline">
                {siteConfig.author}
              </a>
              .
            </Text>

            <Button
              onClick={scrollToTop}
              aria-label="Przewiń na górę strony"
              className="flex justify-center items-center w-50 h-70 rounded-none"
            >
              <Icons.chevronUp className="w-20 h-20" />
            </Button>
          </LayoutContainer>
        </div>
      </footer>
    </>
  );
}
