import Link, { type LinkProps } from 'next/link';
import React, { type PropsWithChildren } from 'react';

type AnchorProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps>;
type ScrollLinkProps = AnchorProps & LinkProps & PropsWithChildren;

interface Props extends ScrollLinkProps {
  offset?: number;
}

export const ScrollLink = ({ children, offset = 50, ...props }: Props) => {
  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();

    const targetId = e.currentTarget.href.replace(/.*\#/, '');
    const elemOffsetTop = document.getElementById(targetId)?.offsetTop;
    const windowHeight = window.innerHeight;
    const scrollTopValue =
      elemOffsetTop === undefined ? windowHeight - offset : elemOffsetTop + windowHeight - offset;

    window.scrollTo({
      top: scrollTopValue,
      behavior: 'smooth',
    });
  };

  return (
    <Link {...props} onClick={handleScroll}>
      {children}
    </Link>
  );
};
